/** @jsx jsx */
import { jsx } from '@emotion/react'

import { SecTitle, navHistory } from '../assets/styles/SectionTitle.styles'
import { Container } from 'react-bootstrap'
import NavPageHistory from './navPageHistory'
import members from '../assets/images/LP-vertere/membros-vertere.png'

const SectionTitle = () => {
  return (
    <section css={SecTitle}>
      <div css={navHistory}>
        <NavPageHistory
          display='flex'
          color='black'
          pageName='O INSTITUTO'
        ></NavPageHistory>
      </div>
      <Container className='sobreNos'>
        <div data-aos="fade-right" className='textContent'>
          <h1 className='titleDesktop'>Sobre Nós</h1>
          <p>
            Somos uma entidade jurídica de direito privado, sem fins lucrativos,
            que tem por objetivo ajudar a transformar a educação no Brasil
            através de práticas pedagógicas ativas e investigativas. Em
            particular, estamos empenhados em promover e democratizar o acesso
            às olimpíadas do conhecimento no Brasil e nos demais países com os
            quais o Instituto mantém parcerias.
          </p>
        </div>
        <div className='img'>
        <img 
          data-aos="fade-left"
            className='members'
            width={575}
            src={members}
            alt='Membros do Instituto Vertere'
          />
        </div>
      </Container>
    </section>
  )
}

export default SectionTitle
