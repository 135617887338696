/** @jsx jsx */
import { jsx } from '@emotion/react'
import { styles } from '../assets/styles/NavPageHistory.styles'
import { Link } from 'gatsby'

const NavPageHistory = ({ pageName, color, bottom, display }) => {
  return (
    <div style={{display: display, color: color, bottom: bottom}} css={styles}>
      <Link to='/'>
        <span style={{color: color, borderBottomColor: color}} className='inicio'>INÍCIO</span>
      </Link>
      <svg
        className='arrowRight'
        width='13'
        height='13'
        viewBox='0 0 13 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path style={{fill: color}}
          d='M12.6827 11.698L1.84929 0.789245C1.42629 0.363209 0.740324 0.363209 0.317245 0.789317C-0.105761 1.21535 -0.105761 1.90604 0.317318 2.33208L10.3846 12.4695L0.317245 22.6075C-0.105761 23.0335 -0.105761 23.7241 0.317318 24.1503C0.528784 24.3633 0.806046 24.4697 1.08331 24.4697C1.36057 24.4697 1.63783 24.3633 1.84937 24.1501L12.6827 13.2408C12.8859 13.0362 13 12.7588 13 12.4694C13 12.1801 12.8859 11.9026 12.6827 11.698Z'
          fill='#C0C6CE'
        />
      </svg>
      <span style={{color: color, borderBottomColor: color}} className='pageName'>{pageName}</span>
    </div>
  )
}

export default NavPageHistory
