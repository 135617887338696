/** @jsx jsx */
import { css } from '@emotion/react'

export const pageBanner = (
  backgroundImage,
  backgroundImageMobile,
  backgroundImageTablet
) => css`
  background: url(${backgroundImage}) no-repeat;
  display: flex;
  position: relative;
  min-height: 380px;
  background-size: cover;
  margin-top: 108px;
  .container {
    align-self: center;
    margin: 0;
  }

  @media (max-width: 500px) {
    background: url(${backgroundImageMobile}) no-repeat;
    position: relative;
    margin-top: 108px;
    width: 100%;
    background-size: cover;
    height: 120px;
    width: 100%;
    min-height: 0;
    margin-bottom: 8px;
    padding-top: 0;
  }
  @media (min-width: 500px) and (max-width: 850px) {
    background: url(${backgroundImageTablet}) no-repeat;
    width: 100%;
    height: 150px;
    background-size: cover;
    width: 100%;
    min-height: 0;
    padding-top: 0;
  }

`
