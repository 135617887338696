/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'

import { pageBanner } from '../assets/styles/PageBanner.styles'
import NavPageHistory from './navPageHistory'

const PageBanner = ({ backgroundImage, backgroundImageMobile, backgroundImageTablet, pageName }) => {
  return (
    <section css={pageBanner(backgroundImage, backgroundImageMobile, backgroundImageTablet)}>
      <div className='container'>
        <NavPageHistory pageName={pageName}></NavPageHistory>
      </div>
    </section>
  )
}

export default PageBanner
