/** @jsx jsx */
import { css } from '@emotion/react'

export const styles = css`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  position: relative;
  bottom: -120px;
  margin-left: 159.6px;
  
  &:hover{
    .inicio {
      border-bottom: 1px solid #ffffff;
      padding-bottom: 3px;
      color: #ffffff;
    }
    .pageName {
      border-bottom: none !important;
      color: #c0c6ce;
    }
  }

  @media (min-width: 500px) and (max-width: 850px) {
    margin: 15px 0px 40px 50px;
    padding: 0;
    bottom: 0;
  }

  @media (max-width: 850px) {
    display: none;
  }
  @media (max-width: 767px) {
    margin-left: 0px;
    bottom: 0;
    .inicio {
      color: #3f4a58 !important;
      :hover {
        line-height: 16px;
        border-bottom: 1px solid #c0c6ce;
        padding-bottom: 3px;
      }
    }
    path {
      fill: #3f4a58;
    }
    .pageName {
      color: #212121 !important;
      line-height: 16px;
      border-bottom: 1px solid #212121 !important;
      padding-bottom: 3px;
      cursor: pointer;
    }
  }
  .inicio {
    color: #c0c6ce;
    :hover {
      line-height: 16px;
      border-bottom: 1px solid #c0c6ce;
      padding-bottom: 3px;
    }
  }
  .pageName {
    color: #ffffff;
    line-height: 16px;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 3px;
    cursor: pointer;
  }
  svg {
    margin-right: 5px;
  }
  a {
    margin-right: 5px;
    text-decoration: none;
  }
`
