/** @jsx jsx */
import { css } from "@emotion/react";


export const styles = css`

margin-top: 74px;
.content {
  margin-top: 69px;
  display: flex;
  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 112px;
  }

  @media (max-width: 1100px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 850px) {
    margin-bottom: 200px;
  }
}
.studentsCircle{
  margin-right: 85px;
  @media (max-width: 900px) {
    display: none;
  }
  
}
.studentCircleTablet{
  display: none;
  @media (min-width: 500px) and (max-width: 850px) {
    display: flex;
    margin-bottom: 112px;
    margin-top: 88px;
    width: 100%;
  }
}
.studentCircleMobile{
  display: none;
  @media (max-width: 375px) {
  display: inline; 
  }
}
.text {
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  width: 623px;
  @media (max-width: 900px) {
    width: 100%;
  }
}
h2 {
  margin-top: 74px;
  padding-bottom: 20px;
  border-bottom: 2px solid rgba(71, 102, 168, 0.5);
  color: #4766A8;
  font-weight: 700;
  @media (min-width: 768px) and (max-width: 1200px) {
    display: none;
  }
  @media (max-width: 768px) {
    border-bottom: none;
    margin-bottom: 17px;
    padding-bottom: 0;
  }
}
.quote {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #6F6F6F;
  strong {
    color: #212121;
  }
  @media (max-width: 900px) {
    display: none;
  }
}

`
