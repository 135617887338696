import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import SobreNos from "@/components/sobre-nos";
import NossaHistoria from "@/components/nossa-historia";

import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import Header from '../components/header-one';
import PageBanner from '../components/page-banner';
import SobreNosTitle from '../components/sobre-nos-title'

import bannerInterview from '../assets/images/LP-vertere/banner-instituto.png'
import bannerInterviewMobile from '../assets/images/LP-vertere/banner-instituto-mobile.png'
import bannerInterviewTablet from '../assets/images/LP-vertere/banner-instituto-tablet.png'

const Sobre = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="O Instituto">
          <Header />
          <PageBanner backgroundImage={bannerInterview} backgroundImageMobile={bannerInterviewMobile} backgroundImageTablet={bannerInterviewTablet} pageName='O INSTITUTO'></PageBanner>
          <SobreNosTitle></SobreNosTitle>
          <SobreNos title='Sobre Nós'/>
          <NossaHistoria />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Sobre;
