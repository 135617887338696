/** @jsx jsx */
import { css } from '@emotion/react'

export const navHistory = css`
  margin-left: 20px;
  margin-bottom: 40px;
  display: none;
  @media (max-width: 850px) {
    display: flex;
  }
`

export const SecTitle = css`

  .studentCircleMobile {
    display: none;
    @media (max-width: 500px) {
      display: inline;
      margin-top: 50px;
      width: 100%;
    }
  }
  @media (min-width: 374px) and (max-width: 768px) {
    position: relative;
    bottom: 0;
  }
  @media (max-width: 768px) {
    @media (min-width: 373px) and (max-width: 767px) {
      position: relative;
      bottom: 0;
    }
  }
  .quote {
    display: none;
    @media (max-width: 900px) {
      margin-top: 31px;
      display: inline;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #6f6f6f !important;
      > strong {
        color: #212121;
      }
    }
  }
  .titleDesktop {
    color: #4766a8;
    font-weight: 700;
    line-height: 38px;
    font-size: 40px;
    line-height: 48px;
    @media (max-width: 768px) {
      font-size: 32px;
    }
  }
  .textContent {
    margin-right: 196px;
    
    @media (min-width: 500px) and (max-width: 850px) {
      margin-right: 200px;
    }
    @media (max-width: 500px) {
      margin: 0;
    }
  }
  .img {
    width: 519px;
  }
  .sobreNos {
    display: flex;
    margin-top: 109px;
    align-items: center;
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      margin-top: 0;
    }
    @media (max-width: 900px) and (min-width: 768px) {
      margin-top: 31px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
      color: #212121;
      width: 408px;
      @media (max-width: 900px) {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .members {
    @media (max-width: 900px) {
      display: none;
    }
  }
  .membersMobile {
    width: 100%;
    @media (min-width: 500px) {
      display: none;
    }
  }
  .membersTablet {
    display: none;
    @media (min-width: 500px) and (max-width: 850px) {
      display: inline;
      width: 100%;
    }
  }
`
