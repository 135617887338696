/** @jsx jsx */
import { jsx } from '@emotion/react'


import membersMobile from '../assets/images/LP-vertere/membros-vertere-mobile.png'
import membersTablet from '../assets/images/LP-vertere/membros-vertere-tablet.png'
import studentCircleMobile from '../assets/images/LP-vertere/student-circle-mobile.png'

import { SecTitle } from '../assets/styles/SectionTitle.styles'
import { Container } from 'react-bootstrap'


const SectionTitle = () => {
  return (
    <section css={SecTitle}>
      <img data-aos="fade-right"
            className='membersTablet'
          src={membersTablet} alt="Membros do Instituto Vertere" />
      <Container className='sobreNos'>
        <div data-aos="fade-right" className='img'>
          
          <img 
            className='membersMobile'
            src={membersMobile}
            alt='Membros do Instituto Vertere'
          />
          
        </div>
        <p data-aos="fade-left" className='quote'>
          “Quando os esforços do educador não se conectam com alguma atividade
          que a criança está fazendo por sua própria iniciativa, independente do
          educador, a educação fica reduzida a uma pressão externa.”  -{' '}
          <strong>John Dewey</strong>
        </p>
      </Container>
      <img
        data-aos="fade-right"
        className='studentCircleMobile'
        src={studentCircleMobile}
        alt='Estudantes fazendo um circulo com as uma sobre as outras no centro.'
      />
    </section>
  )
}

export default SectionTitle
