/** @jsx jsx */
import { Container } from 'react-bootstrap'

import { jsx } from '@emotion/react'

import { styles } from '../assets/styles/NossaHistoria.styles'
import studentsCircle from '../assets/images/LP-vertere/student-circle.png'
import studentCircleTablet from '../assets/images/LP-vertere/student-circle-tablet.png'

const NossaHistoria = () => {
  return (
    <section css={styles}>
      <Container>
        <h2>Nossa História</h2>
        <Container className='content'>
          <div data-aos="fade-right" className='studentsCircle'>
            <img
              src={studentsCircle}
              alt='Estudantes fazendo um circulo com as uma sobre as outras no centro.'
            />
          </div>
          <div data-aos="fade-left">
            <p className='quote'>
              “Quando os esforços do educador não se conectam com alguma
              atividade que a criança está fazendo por sua própria iniciativa,
              independente do educador, a educação fica reduzida a uma pressão
              externa.” - <strong>John Dewey</strong>
            </p>
            <p className='text'>
              A perspectiva educacional defendida por John Dewey no fim do
              século 19 deu origem às várias correntes pedagógicas baseadas em
              projetos ou problemas. Essa tradição se desenvolveu de forma rica
              na educação brasileira, passando por Anísio Teixeira, Paulo
              Freire, a fundação de diferentes universidades e de políticas
              educacionais e, também, pelo desenvolvimento das olimpíadas de
              conhecimento. 
            </p>
            <p className='text'>
              As olimpíadas de conhecimento, compartilhando do mesmo espírito,
              também surgiram no fim do século 19, e começaram a enriquecer as
              escolas brasileiras a partir dos anos 1960. Inicialmente focadas
              em matemática, física, química e astronomia, elas foram se
              diversificando e, hoje, cobrem os mais diversos temas. 
            </p>
            <p className='text'>
              A partir de 2016, alguns professores envolvidos desde o fim dos
              anos 1990 com diferentes olimpíadas e também com diferentes
              instâncias de educação formal e informal, começaram a conversar
              sobre os desafios para aprofundar o papel das olimpíadas em uma
              educação transformadora.
            </p>
            <p className='text'>
              O aumento do alcance, a digitalização, a convergência entre as
              diversas olimpíadas e a tradução das suas práticas para o
              cotidiano das escolas eram temas discutidos. A conclusão foi que
              uma instituição que pudesse apoiar na infra-estrutura e na visão
              estratégica das diferentes olimpíadas, bem como sua integração
              efetiva nas práticas das escolas, beneficiaria as olimpíadas, as
              escolas e, principalmente, os estudantes. Assim, nasceu o
              Instituto Vertere. 
            </p>
            <p className='text'>
              Desde então, trabalhando em rede com colaboradores nacionais e
              internacionais, o Instituto tem apoiado e incubado olimpíadas de
              conhecimento e iniciativas afins, fornecendo estrutura
              institucional e jurídica, captando patrocinadores e conectando a
              iniciativa privada à educação transformadora, desenvolvendo
              plataformas de tecnologia para democratizar o acesso à educação de
              qualidade, e fornecendo consultoria pedagógica para escolas
              desenvolverem suas práticas ativas e investigativas.
            </p>
          </div>
        </Container>
      </Container>
        <img
          data-aos="fade-right"
          className='studentCircleTablet'
          src={studentCircleTablet}
          alt='Estudantes fazendo um circulo com as uma sobre as outras no centro.'
        />
    </section>
  )
}

export default NossaHistoria
